<template>
  <!-- Page Content  -->
  <b-container fluid>
    <!-- BEGIN:Modal Create -->
    <b-modal
      id="modal-create"
      v-model="isModalCreate"
      size="lg"
      centered
      title="Tambah Data User"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group>
          <label class="block" for="namaLengkap"
            >Nama Lengkap: <span class="text-danger">*</span></label
          >
          <input-primary
            id="namaLengkap"
            v-model="form.namaLengkap"
            placeholder="Masukkan nama lengkap"
            required
            max-length="100"
          />
        </b-form-group>
        <b-form-group>
          <label for="idUpbjj">UPBJJ:</label>
          <input-datalists
            id="idUpbjj"
            class="w-full text-sm"
            placeholder="Pilih UPBJJ"
            v-model="form.idUpbjj"
            label="keterangan"
            :options="
              (listUpbjj &&
                listUpbjj.map(data => {
                  return {
                    ...data,
                    keterangan: data.kode_upbjj + ` | ` + data.nama_upbjj
                  }
                })) ||
                []
            "
            :reduce="data => data.kode_upbjj"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group>
          <label class="block" for="email"
            >Email: <span class="text-danger">*</span></label
          >
          <input-primary
            type="email"
            maxLength="100"
            :regex="/\s/gi"
            regexMessage="Tidak dapat memuat spasi"
            v-model="form.email"
            @input="inputEmail()"
            lowerCase
            required
            id="email"
            :isError="!isValidEmail"
          />
          <collapse-transition>
            <div
              class="px-2 text-danger mt-2"
              v-show="isError.email.validEmail"
            >
              Email harus mengandung karakter @
            </div>
          </collapse-transition>
        </b-form-group>
        <!-- <b-form-group>
          <label class="block" for="akun365"
            >Email Office 365: <span class="text-danger">*</span></label
          >
          <input-primary
            type="akun365"
            maxLength="100"
            :regex="/\s/gi"
            regexMessage="Tidak dapat memuat spasi"
            v-model="form.akun365"
            @input="inputAkun365()"
            lowerCase
            required
            id="akun365"
            :isError="!isValidAkun365"
          />
          <collapse-transition>
            <div
              class="px-2 text-danger mt-2"
              v-show="isError.akun365.validAkun365"
            >
              Email Office 365 harus menggunakan @ecampus
            </div>
          </collapse-transition>
        </b-form-group> -->
        <b-form-group>
          <label for="idUnit">Unit:</label
          ><span class="text-danger ml-1">*</span>
          <input-datalists
            id="idUnit"
            class="w-full text-sm"
            placeholder="Pilih Unit"
            v-model="form.idUnit"
            @input="
              ;[
                getGroup_($event),
                (form.idGroup = null),
                getUnitPks_($event),
                (form.idUnitPks = null)
              ]
            "
            label="keterangan"
            required
            :options="
              (listUnit &&
                listUnit.map(data => {
                  return {
                    ...data,
                    keterangan: data.kode_unit + ` | ` + data.nama_unit
                  }
                })) ||
                []
            "
            :reduce="data => data.id"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group>
          <label for="idUnitPks">Unit PKS:</label
          ><span class="text-danger ml-1">*</span>
          <input-datalists
            id="idUnitPks"
            class="w-full text-sm"
            placeholder="Pilih Unit"
            v-model="form.idUnitPks"
            label="keterangan"
            required
            :options="
              (listUnitPks &&
                listUnitPks.map(data => {
                  return {
                    ...data,
                    keterangan: data.kode_unit_pks + ` | ` + data.nama_unit_pks
                  }
                })) ||
                []
            "
            :reduce="data => data.id"
            :clearable="false"
            :disabled="!form.idUnit"
          />
        </b-form-group>
        <b-form-group>
          <label for="idGroup">Group:</label
          ><span class="text-danger ml-1">*</span>
          <input-datalists
            id="idGroup"
            class="w-full text-sm"
            placeholder="Pilih Group"
            v-model="form.idGroup"
            label="keterangan"
            required
            :options="
              (listGroup &&
                listGroup.map(data => {
                  return {
                    ...data,
                    keterangan: data.kode_group + ` | ` + data.nama_group
                  }
                })) ||
                []
            "
            :reduce="data => data.id"
            :clearable="false"
            :disabled="!form.idUnit"
          />
        </b-form-group>
        <b-form-group>
          <label class="block" for="idPassword"
            >Password: <span class="text-danger">*</span></label
          >
          <div>
            <input-primary
              class="w-50 d-inline-block"
              id="idPassword"
              type="password"
              v-model="form.idPassword"
              placeholder="Masukkan password"
              required
              max-length="100"
            />
            <i
              class="ri-2x self-center ml-2 cursor-pointer"
              :class="showPassword ? 'ri-eye-close-line' : 'ri-eye-line'"
              @click="showHidePassword()"
            ></i>
          </div>
        </b-form-group>
        <b-form-group>
          <label for="idStatusAktif">Status Aktif:</label
          ><span class="text-danger ml-1">*</span>
          <input-datalists
            id="idStatusAktif"
            class="w-full text-sm"
            placeholder="Pilih Status Aktif"
            v-model="form.idStatusAktif"
            label="nama_status"
            required
            :options="listStatusAktif || []"
            :reduce="data => data.id"
            :clearable="false"
          />
        </b-form-group>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalCreate">Batal</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="createData"
              :disabled="!isValidForm"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Create -->
    <!-- BEGIN:Modal Edit -->
    <b-modal
      id="modal-edit"
      v-model="isModalEdit"
      size="lg"
      centered
      title="Ubah Data User"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group>
          <label class="block" for="namaLengkap"
            >Nama Lengkap: <span class="text-danger">*</span></label
          >
          <input-primary
            id="namaLengkap"
            v-model="modal.namaLengkap"
            placeholder="Masukkan nama lengkap"
            required
            max-length="100"
          />
        </b-form-group>
        <b-form-group>
          <label for="idUpbjj">UPBJJ:</label>
          <input-datalists
            id="idUpbjj"
            class="w-full text-sm"
            placeholder="Pilih UPBJJ"
            v-model="modal.idUpbjj"
            label="keterangan"
            :options="
              (listUpbjj &&
                listUpbjj.map(data => {
                  return {
                    ...data,
                    keterangan: data.kode_upbjj + ` | ` + data.nama_upbjj
                  }
                })) ||
                []
            "
            :reduce="data => data.kode_upbjj"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group>
          <label class="block" for="email"
            >Email: <span class="text-danger">*</span></label
          >
          <input-primary
            type="email"
            maxLength="100"
            :regex="/\s/gi"
            regexMessage="Tidak dapat memuat spasi"
            v-model="modal.email"
            @input="inputEmail()"
            lowerCase
            required
            id="email"
            :isError="!isValidEmail"
          />
          <collapse-transition>
            <div
              class="px-2 text-danger mt-2"
              v-show="isError.email.validEmail"
            >
              Email harus mengandung karakter @
            </div>
          </collapse-transition>
        </b-form-group>
        <!-- <b-form-group>
          <label class="block" for="akun365"
            >Email Office 365: <span class="text-danger">*</span></label
          >
          <input-primary
            type="akun365"
            maxLength="100"
            :regex="/\s/gi"
            regexMessage="Tidak dapat memuat spasi"
            v-model="modal.akun365"
            @input="inputAkun365()"
            lowerCase
            required
            id="akun365"
            :isError="!isValidAkun365"
          />
          <collapse-transition>
            <div
              class="px-2 text-danger mt-2"
              v-show="isError.akun365.validAkun365"
            >
              Email Office 365 harus menggunakan @ecampus
            </div>
          </collapse-transition>
        </b-form-group> -->
        <b-form-group>
          <label for="idUnit">Unit:</label
          ><span class="text-danger ml-1">*</span>
          <input-datalists
            id="idUnit"
            class="w-full text-sm"
            placeholder="Pilih Unit"
            v-model="modal.idUnit"
            @input="
              ;[
                getGroup_($event),
                (modal.idGroup = null),
                getUnitPks_($event),
                (modal.idUnitPks = null)
              ]
            "
            label="keterangan"
            required
            :options="
              (listUnit &&
                listUnit.map(data => {
                  return {
                    ...data,
                    keterangan: data.kode_unit + ` | ` + data.nama_unit
                  }
                })) ||
                []
            "
            :reduce="data => data.id"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group>
          <label for="idUnitPks">Unit PKS:</label
          ><span class="text-danger ml-1">*</span>
          <input-datalists
            id="idUnitPks"
            class="w-full text-sm"
            placeholder="Pilih Unit"
            v-model="modal.idUnitPks"
            label="keterangan"
            required
            :options="
              (listUnitPks &&
                listUnitPks.map(data => {
                  return {
                    ...data,
                    keterangan: data.kode_unit_pks + ` | ` + data.nama_unit_pks
                  }
                })) ||
                []
            "
            :reduce="data => data.id"
            :clearable="false"
            :disabled="!modal.idUnit"
          />
        </b-form-group>
        <b-form-group>
          <label for="idGroup">Group:</label
          ><span class="text-danger ml-1">*</span>
          <input-datalists
            id="idGroup"
            class="w-full text-sm"
            placeholder="Pilih Group"
            v-model="modal.idGroup"
            label="keterangan"
            required
            :options="
              (listGroup &&
                listGroup.map(data => {
                  return {
                    ...data,
                    keterangan: data.kode_group + ` | ` + data.nama_group
                  }
                })) ||
                []
            "
            :reduce="data => data.id"
            :clearable="false"
            :disabled="!modal.idUnit"
          />
        </b-form-group>
        <b-form-group>
          <label class="block" for="idPassword"
            >Password: <span class="text-danger">*</span></label
          >
          <div>
            <input-primary
              class="w-50 d-inline-block"
              id="idPassword"
              type="password"
              v-model="modal.idPassword"
              placeholder="Masukkan password"
              required
              max-length="100"
            />
            <i
              class="ri-2x self-center ml-2 cursor-pointer"
              :class="showPassword ? 'ri-eye-close-line' : 'ri-eye-line'"
              @click="showHidePassword()"
            ></i>
          </div>
        </b-form-group>
        <b-form-group>
          <label for="idStatusAktif">Status Aktif:</label
          ><span class="text-danger ml-1">*</span>
          <input-datalists
            id="idStatusAktif"
            class="w-full text-sm"
            placeholder="Pilih Status Aktif"
            v-model="modal.idStatusAktif"
            label="nama_status"
            required
            :options="listStatusAktif || []"
            :reduce="data => data.id"
            :clearable="false"
          />
        </b-form-group>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalEdit">Batal</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="editData"
              :disabled="!isValidModal"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Edit -->
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Manajemen User</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-25"
                    id="filter-input"
                    v-model="filterTable"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <div class="d-inline-block float-right">
                    <b-button @click="showModalCreate" variant="primary"
                      >Tambah Data<i class="ri-add-line ml-2"></i
                    ></b-button>
                  </div>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="thead"
                :items="listUser"
                class="mt-2"
                :busy="isLoadingTable"
                :filter="filterTable"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
                show-empty
              >
                <template v-slot:cell(kode_upbjj)="list">
                  <div
                    v-if="!['null', null, ''].includes(list.item.kode_upbjj)"
                  >
                    {{ list.item.kode_upbjj }} | {{ list.item.nama_upbjj }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:cell(kode_group)="list">
                  <div v-if="list.item.kode_group">
                    {{ list.item.kode_group }} | {{ list.item.nama_group }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:cell(kode_unit)="list">
                  <div v-if="list.item.kode_unit">
                    {{ list.item.kode_unit }} | {{ list.item.nama_unit }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:cell(aksi)="list">
                  <div class="flex">
                    <b-button
                      class="px-3 m-1"
                      variant=" bg-success"
                      v-b-tooltip="'Edit data'"
                      @click="showModalEdit(list.item)"
                      ><i class="ri-edit-line m-0"></i>Edit</b-button
                    >
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div
                    class="
                        bg-light
                        text-danger
                        font-weight-bold
                        mx-auto
                        text-center
                      "
                  >
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { Vue } from 'vue-property-decorator'
import store from '@/store/index'
import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'menuKhusus.manajemenUser',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status =
        this.form.namaLengkap &&
        this.form.email &&
        // this.form.akun365 &&
        // this.form.idUpbjj &&
        this.form.idUnit &&
        this.form.idUnitPks &&
        this.form.idGroup &&
        this.form.idPassword !== null &&
        this.isValidEmail
      // this.isValidAkun365
      return status
    },
    isValidModal() {
      const status =
        this.modal.namaLengkap &&
        this.modal.email &&
        // this.modal.akun365 &&
        // this.modal.idUpbjj &&
        this.modal.idUnit &&
        this.modal.idUnitPks &&
        this.modal.idGroup &&
        this.modal.idPassword !== null &&
        this.isValidEmail
      // this.isValidAkun365
      return status
    },
    isValidEmail() {
      const { validEmail } = this.isError.email
      return !validEmail
    },
    isValidAkun365() {
      const { validAkun365 } = this.isError.akun365
      return !validAkun365
    }
  },
  async mounted() {
    this.innitData()
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Show hide password
     */
    showHidePassword() {
      this.showPassword
        ? (this.showPassword = false)
        : (this.showPassword = true)
      const passwordField = document.querySelector('#idPassword')
      if (passwordField.getAttribute('type') === 'password') {
        passwordField.setAttribute('type', 'text')
      } else passwordField.setAttribute('type', 'password')
    },
    /**
     * Input Email
     */
    inputEmail() {
      this.isError.email = {
        validEmail: false
      }
      if (this.form.email && this.form.email !== null) {
        if (!this.form.email.includes('@')) {
          this.isError.email.validEmail = true
        }
      } else if (this.modal.email && this.modal.email !== null) {
        if (!this.modal.email.includes('@')) {
          this.isError.email.validEmail = true
        }
      } else {
        this.isError.email.validEmail = false
      }
    },
    /**
     * Input Akun 365
     */
    inputAkun365() {
      this.isError.akun365 = {
        validAkun365: false
      }
      if (this.form.akun365 && this.form.akun365 !== null) {
        if (!this.form.akun365.includes('@ecampus')) {
          this.isError.akun365.validAkun365 = true
        }
      } else if (this.modal.akun365 && this.modal.akun365 !== null) {
        if (!this.modal.akun365.includes('@ecampus')) {
          this.isError.akun365.validAkun365 = true
        }
      } else {
        this.isError.akun365.validAkun365 = false
      }
    },
    /**
     * Reset Value
     */
    resetForm() {
      this.form.namaLengkap = null
      this.form.email = null
      this.form.akun365 = null
      this.form.idUpbjj = null
      this.form.idUnit = null
      this.form.idUnitPks = null
      this.form.idGroup = null
      this.form.idPassword = null
      this.form.idStatusAktif = null
      this.showPassword = false
    },
    resetModal() {
      this.modal.namaLengkap = null
      this.modal.email = null
      this.modal.akun365 = null
      this.modal.idUpbjj = null
      this.modal.idUnit = null
      this.modal.idUnitPks = null
      this.modal.idGroup = null
      this.modal.idPassword = null
      this.modal.idStatusAktif = null
      this.showPassword = false
    },
    /**
     * Get list user
     */
    async getAllUser_() {
      this.isLoadingTable = true
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/get_all_user`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listUser = res.data.data
          /** Set the initial number of items */
          this.totalRows = this.listUser.length
          this.isLoadingTable = false
        })
        .catch(err => {
          console.log('ERROR: ', err)
          this.isLoadingTable = false
          throw new Error(err)
        })
      return result
    },
    /**
     * Get detail user
     */
    async getDetailUser_(idUser) {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/get_detail_user?id_user=${idUser}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.detailUser = res.data
        })
        .catch(err => {
          console.log('ERROR: ', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data UPBJJ
     */
    async getUpbjj_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getUpbjj`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listUpbjj = res.data.data
        })
        .catch(err => {
          console.log('ERROR: ', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data Unit
     */
    async getUnit_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getUnit`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listUnit = res.data.data
        })
        .catch(err => {
          console.log('ERROR: ', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data Unit PKS by Unit
     */
    async getUnitPks_(idUnit) {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getUnitPks?id_unit=${idUnit}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listUnitPks = res.data.data
        })
        .catch(err => {
          console.log('ERROR: ', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data Unit
     */
    async getGroup_(idUnit) {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getGroup?id_unit=${idUnit}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listGroup = res.data.data
        })
        .catch(err => {
          console.log('ERROR: ', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * CREATE DATA
     */
    /** Show Modal */
    showModalCreate() {
      this.resetForm()
      this.form.idStatusAktif = 1
      this.isModalCreate = true
    },
    /** Close Modal */
    closeModalCreate() {
      this.resetForm()
      this.isModalCreate = false
    },
    /** Create */
    async createData() {
      // console.log('form: ', this.form)
      const bodyFormData = new URLSearchParams()
      bodyFormData.append('name', this.form.namaLengkap)
      bodyFormData.append(
        'kode_upbjj',
        ['null', null, ''].includes(this.form.idUpbjj)
          ? null
          : this.form.idUpbjj
      )
      bodyFormData.append('email', this.form.email)
      bodyFormData.append('password', this.form.idPassword)
      bodyFormData.append('id_group', this.form.idGroup)
      bodyFormData.append('id_unit', this.form.idUnit)
      bodyFormData.append('id_unit_pks', this.form.idUnitPks)
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/register`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          console.log('res: ', res)
          this.isModalCreate = false
          this.getAllUser_()
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR: ', err)
          this.isModalCreate = false
          this.getAllUser_()
          this.$toast.error('Gagal tambah data')
          throw new Error(err)
        })
      return result
    },
    /**
     * EDIT DATA
     */
    /** Show Modal */
    showModalEdit(data) {
      // console.log(data)
      this.resetModal()
      this.modal.id = data.id
      this.modal.namaLengkap = data.name
      this.modal.email = data.email
      // this.modal.akun365 = data.akun365
      this.modal.idUpbjj = ['null', null, ''].includes(data.kode_upbjj)
        ? null
        : data.kode_upbjj
      this.modal.idUnit = data.id_unit
      this.modal.idGroup = data.id_group
      this.getUnitPks_(this.modal.idUnit)
      this.getGroup_(this.modal.idUnit)
      this.isModalEdit = true
    },
    /** Close Modal */
    closeModalEdit() {
      this.resetModal()
      this.isModalEdit = false
    },
    /** Edit */
    async editData() {
      // console.log('modal: ', this.modal)
      const bodyFormData = new FormData()
      bodyFormData.append('id', this.modal.id)
      bodyFormData.append('name', this.modal.namaLengkap)
      bodyFormData.append(
        'kode_upbjj',
        ['null', null, ''].includes(this.modal.idUpbjj)
          ? null
          : this.modal.idUpbjj
      )
      bodyFormData.append('password', this.modal.idPassword)
      bodyFormData.append('email', this.modal.email)
      bodyFormData.append('status_aktif', 1)
      bodyFormData.append('id_group', this.modal.idGroup)
      bodyFormData.append('id_unit', this.modal.idUnit)
      bodyFormData.append('id_unit_pks', this.modal.idUnitPks)
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/update_user`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          console.log('res: ', res)
          this.isModalEdit = false
          this.getAllUser_()
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR: ', err)
          this.isModalEdit = false
          this.getAllUser_()
          this.$toast.error('Gagal ubah data')
          throw new Error(err)
        })
      return result
    },
    /**
     * Innit Data
     */
    async innitData() {
      this.getAllUser_()
      await Promise.all([this.getUpbjj_(), this.getUnit_()])
    }
  },
  data() {
    return {
      showPassword: false,
      // Tabel Options
      isLoadingTable: false,
      filterTable: null,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPage: 1,
      totalRows: 1,
      // isModal
      isModalCreate: false,
      isModalEdit: false,
      isModalDelete: false,
      // isError
      isError: {
        email: { validEmail: false },
        akun365: { validAkun365: false }
      },
      // List Data
      listUpbjj: [],
      listUnit: [],
      listUnitPks: [],
      listUser: [],
      listGroup: [],
      listStatusAktif: [
        {
          id: 1,
          nama_status: 'Aktif'
        },
        {
          id: 0,
          nama_status: 'Tidak Aktif'
        }
      ],
      // Form
      detailUser: {},
      form: {
        namaLengkap: null,
        idUpbjj: null,
        email: null,
        akun365: null,
        idUnit: null,
        idUnitPks: null,
        idGroup: null,
        idPassword: null,
        idStatusAktif: null
      },
      modal: {
        id: null,
        namaLengkap: null,
        idUpbjj: null,
        email: null,
        akun365: null,
        idUnit: null,
        idUnitPks: null,
        idGroup: null,
        idPassword: null,
        idStatusAktif: null
      },
      // Table
      thead: [
        {
          label: 'Nama Lengkap',
          key: 'name',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'UPBJJ',
          key: 'kode_upbjj',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Email',
          key: 'email',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Group',
          key: 'kode_group',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Unit',
          key: 'kode_unit',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Status Aktif',
          key: 'ketstatusaktif',
          class: 'text-center',
          sortable: true
        },
        { label: 'Aksi', key: 'aksi', class: 'text-center' }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    const idUnitKs = store.state.authService.idUnitKs
    if (MENU_ROLE.MENU_KHUSUS_MANAJEMEN_USER.includes(String(idUnitKs))) {
      return next()
    } else {
      Vue.$toast.error('Anda tidak memiliki hak akses')
      return next({ name: 'sidebar.dashboard' })
    }
  }
}
</script>
